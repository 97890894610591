import React, { useEffect, useState } from "react";
import "../LandingPage/LandingPage.css";
import "../Team/CreateTeam.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import mainlogo from "../../assets/img/banner.jpg";
import NewCibona from "../../assets/img/Cibona-New-logo.png";
import { Link, useSearchParams } from "react-router-dom";
import AllEventCard from "../AllEvent/AllEventCard";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsDot, BsFilterLeft } from "react-icons/bs";
import { IoFastFoodOutline, IoLocationOutline } from "react-icons/io5";
import { MdDateRange, MdSchedule } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { LuParkingCircle } from "react-icons/lu";
import { FaCar, FaSearch } from "react-icons/fa";
import CibonaZagreb from "../../assets/img/CIBONA-ZAGREB-logo.png";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { API_DOMAIN } from "../../utils/GlobalConst";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import moment from "moment";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import UpcomingEvent from "../AllEvent/UpcomingEvent";

function TeamPage() {
  const [searchParams] = useSearchParams();
  const teamid = searchParams.get("teamid");
  console.log(teamid);
  const cid = searchParams.get("cid");

  const navigate = useNavigate();
  const [allData, setAllData] = useState(false);
  const [seasonPassbtn, setSeasonPassbtn] = useState(false);
  const [bundlePassbtn, setBundlePassbtn] = useState(false);
  const [calendarbtn, setCalendarbtn] = useState(false);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [startDate, setStartDate] = useState(tomorrow);
  const [endDate, setEndDate] = useState(tomorrow);
  const [events, setevents] = useState([]);
  const [AllEvents, setAllEvents] = useState([]);
  const [SeasonalData, setSeasonalData] = useState([]);
  const [BundleData, setBundleData] = useState([]);

  // const events = [
  //   { title: "Cibona vs Zadar", start: new Date() },
  //   { title: "Cibona vs Zadar", start: new Date() },
  // ];

  // language variable
  const [text_emptyseason, setText_emptyseason] = useState(
    "No Season Pass Found"
  );
  const [text_nobundlefound, setText_nobundlefound] =
    useState("No Bundle Found");

  const Breadcrumbs = ({ breadcrumbs }) => {
    return (
      <div className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <span key={index}>
            {index > 0 && <span className="breadcrumbs-separator">/</span>}
            {breadcrumb.link ? (
              <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
            ) : (
              <span>{breadcrumb.label}</span>
            )}
          </span>
        ))}
      </div>
    );
  };

  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Teams", link: "#" },
    { label: "KK Cibona" },
  ];

  const getAllEvent = () => {
    var API_URL = API_DOMAIN + `event/get/byteamid?teamid=${teamid}&limit=10`;
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("all events ", response.data);
        var res = response.data.data.events;
        setAllEvents(res);
        console.log(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const getspecificdateData = () => {
    var API_URL =
      API_DOMAIN +
      "event/get/byteamid/specificdate?teamid=fd683cf5-b7b4-4f5b-af1f-5e7c26f793a3&specificDate=07-10-2025";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("specific date wise event ", response.data.data);

        var res = response.data.data;
        console.log(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const handleDatesSet = (dateInfo) => {
    const startDate = new Date(dateInfo.start); // Start of visible range
    const formattedMonth = moment(startDate).format("MM YYYY"); // Format for the API
    console.log(formattedMonth);

    const url =
      API_DOMAIN +
      `event/get/byteamid/calendar?teamid=${teamid}&month=${formattedMonth}`;
    console.log(url);

    getCalendarData(url);
  };
  const getCalendarData = (url) => {
    var API_URL = url;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response) => {
        console.log("month wise events ", response.data.data[0].events);

        var res = response.data.data[0].events;
        const formattedEvents = res.map((event) => ({
          title: event.EventTitle, // Adjust based on your API response
          start: event.StartDate, // Ensure date is in ISO 8601 format
          end: event.EndDate,
          starttime: event.StartTime, // Ensure date is in ISO 8601 format
          endtime: event.EndTime, // Optional
        }));
        console.log(formattedEvents);

        setevents(formattedEvents);
        console.log(res[0]?.EventTitle);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };

  const SeasonalCard = () => {
    var API_URL =
      API_DOMAIN + `seasonal/getall/byuser?uid=6543348a5a5f6ddb0f89b9ef`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("seasonal data ", response.data.data);
        var res = response.data.data;
        setSeasonalData(res);
        console.log(response.data.data?.event_details);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  const BundlegetData = () => {
    var API_URL =
      API_DOMAIN + `bundle/getall/byuser?uid=6543348a5a5f6ddb0f89b9ef`;

    axios
      .get(API_URL)
      .then((response) => {
        console.log("bundle data ", response.data.data);
        var res = response.data.data;
        setBundleData(res);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  useEffect(() => {
    getAllEvent();
    getCalendarData();
    SeasonalCard();
    BundlegetData();
  }, []);
  return (
    <>
      <Header />
      <div className="wrapper" id="otherPages">
        <div className="hero-banner-landing">
          <img className="landing-main-img" src={mainlogo} alt="" />
          <div className="league-page-search">
            <input
              id="outlined-basic"
              variant="outlined"
              className="league-page-search-input"
              fullWidth
              placeholder="Search"
              label="Search"
            />
            <div className="league-page-search-icon">
              <FaSearch />
            </div>
          </div>
          <div className="league-page-search-breadcrumb">
            {/* Home / Leagues / ABA League */}
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
          <div className="league-page-search-logo container">
            <img src={CibonaZagreb} alt="" className="team-logo" />
          </div>
          <div>
            {" "}
            <h2 className="container mt-3">
              <img src={NewCibona} alt="" /> <b>KK CIBONA ZAGREB</b>
            </h2>
            <p className="container">
              Košarkaški klub Cibona, commonly referred to as Cibona Zagreb or
              simply Cibona, is a men's professional basketball club based in
              Zagreb, Croatia. The club is a founding member and shareholder of
              the Adriatic Basketball Association, and competes in the Adriatic
              League and the Croatian League.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="d-flex flex-wrap gap-3">
            <button
              className="new-main-tab "
              onClick={() => [
                setAllData(true),
                setSeasonPassbtn(false),
                setBundlePassbtn(false),
                setCalendarbtn(false),
              ]}
            >
              Single Match Tickets
            </button>
            <button
              className="new-main-tab"
              onClick={() => [
                setSeasonPassbtn(true),
                setBundlePassbtn(false),
                setAllData(false),
                setCalendarbtn(false),
              ]}
            >
              Seasons Pass
            </button>
            <button
              className="new-main-tab"
              onClick={() => [
                setSeasonPassbtn(false),
                setBundlePassbtn(true),
                setAllData(false),
                setCalendarbtn(false),
              ]}
            >
              Bundle Packages
            </button>
            <button
              className="new-main-tab calendar-tab"
              onClick={() => [
                setSeasonPassbtn(false),
                setBundlePassbtn(false),
                setAllData(false),
                setCalendarbtn(true),
              ]}
            >
              Calendar
            </button>
            <button className="new-main-tab calendar-tab">About</button>
          </div>
          {allData === true ? (
            <>
              <div className="main-title position-relative mt-4">
                <h4 className="fs-2 fw-bold">Single Match Tickets</h4>
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-3">
                    <div className="form-control filter-team d-flex">
                      <MdDateRange className="icon-team" />
                      <DatePicker
                        //   showIcon
                        className="h_40 mx-1 form-control form-control-BR w-100"
                        selected={startDate}
                        dateFormat="dd MMM,yyyy"
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div className="form-control filter-team d-flex">
                      <IoLocationOutline className="icon-team" />
                      <input
                        type="text"
                        className="h_40 form-control form-control-BR w-100"
                        name=""
                        id=""
                        placeholder="Location"
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <div className="form-control filter-team d-flex">
                      <BsFilterLeft className="icon-team" />
                      <select
                        name=""
                        id=""
                        className="h_40 form-control form-control-BR w-100"
                      >
                        <option value=""> Teams</option>
                      </select>
                    </div>
                    <div className="form-control filter-team d-flex">
                      <BsFilterLeft className="icon-team" />
                      <input
                        type="text"
                        className="h_40 form-control form-control-BR w-100"
                        name=""
                        id=""
                        placeholder="Calendar View"
                      />
                    </div>
                    <div className="form-control filter-team d-flex">
                      <BsFilterLeft className="icon-team" />
                      <select
                        name=""
                        id=""
                        className="h_40 form-control form-control-BR w-100"
                      >
                        <option value=""> Sort</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                {AllEvents.length > 0 &&
                  AllEvents.map((e) => {
                    return (
                      <>
                        <div className="new-card-team d-flex justify-content-between  mt-3">
                          <div className="d-flex">
                            <img
                              src={
                                e.EventImagePath === "path"
                                  ? img1
                                  : e.EventImagePath
                              }
                              alt=""
                              className="card-img-team"
                            />
                            <div className="m-3 responsive-team-card">
                              <h3> {e.EventTitle}</h3>
                              <span>
                                {moment
                                  .utc(e?.StartDate)
                                  .format("DD MMM, yyyy")}{" "}
                                • {moment.utc(e.StartDate).format("ddd")},{" "}
                                {moment.utc(e?.StartTime).format("hh:mm a")}
                              </span>{" "}
                              <br />
                              <span>
                                {e?.venuedetails[0].venuename} ({" "}
                                {e?.venuedetails[0].address},{" "}
                                {e?.venuedetails[0].city},{" "}
                                {e?.venuedetails[0].state},{" "}
                                {e?.venuedetails[0].country},{" "}
                                {e?.venuedetails[0].zipcode})
                              </span>
                            </div>
                          </div>
                          <button
                            className="buybtn-team mx-3"
                            onClick={() =>
                              navigate(`/event-details/${e?.event_unique_id}`)
                            }
                          >
                            Buy Tickets
                          </button>
                        </div>
                      </>
                    );
                  })}
              </div>
            </>
          ) : (
            <>
              {seasonPassbtn === true ? (
                <>
                  <div className="main-title position-relative mt-4">
                    <h4 className="fs-2 fw-bold">Seasons Passes</h4>
                    {SeasonalData.length === 0 ? (
                      <>
                        <div className="main-card mt-4">
                          <h3 className="text-center font-16 mt-3 fw-bold">
                            {text_emptyseason}
                          </h3>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="row"
                          data-ref="event-filter-content"
                          id="otherPages"
                        >
                          {SeasonalData.map((e, index) => {
                            return (
                              <>
                                <div
                                  className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                                  data-ref="mixitup-target"
                                >
                                  <div className="main-card mt-4">
                                    <div className="event-thumbnail">
                                      <Link to={"#"} className="thumbnail-img">
                                        <img
                                          src={
                                            e?.url === "" ||
                                            e?.url === undefined
                                              ? img1
                                              : e?.url
                                          }
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                    <div className="event-content">
                                      <Link
                                        to={"#"}
                                        className="event-title event-css"
                                      >
                                        {e?.ticketname}
                                      </Link>

                                      <div className="publish-date">
                                        <p className="season-details-team">
                                          {e.event_count} Events{" "}
                                          <BsDot
                                            style={{
                                              width: "23px",
                                              height: "23px",
                                            }}
                                          />
                                          {e?.currency} {e?.price}
                                          <br />{" "}
                                          {e?.isFoodAndBeverages === true ? (
                                            <>
                                              <IoFastFoodOutline
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />{" "}
                                              Food and drink included
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <IoFastFoodOutline
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />{" "}
                                              NA
                                            </>
                                          )}
                                          <br />
                                          {e.isParking === true ? (
                                            <>
                                              <FaCar
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />{" "}
                                              Parking pass (
                                              {e.ParkingLevel === ""
                                                ? ""
                                                : e.ParkingLevel}
                                              )
                                            </>
                                          ) : (
                                            <>
                                              <FaCar
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />{" "}
                                              NA
                                            </>
                                          )}
                                        </p>
                                      </div>
                                      <button
                                        className="main-btn btn-hover w-100 mt-4"
                                        onClick={() =>
                                          navigate(
                                            `/seasonalevent/${e?.seasonalticketid}`,
                                            {
                                              state: {
                                                seasonalticketid:
                                                  e?.seasonalticketid,
                                              },
                                            }
                                          )
                                        }
                                      >
                                        View Details{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                          {/* <div
                                  className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                                  data-ref="mixitup-target"
                                >
                                  <div className="main-card mt-4">
                                    <div className="event-thumbnail">
                                      <Link to={"#"} className="thumbnail-img">
                                        <img src={img1} alt="" />
                                      </Link>
                                    </div>
                                    <div className="event-content">
                                      <Link
                                        to={"#"}
                                        className="event-title event-css"
                                      >
                                        Gold Tier
                                      </Link>

                                      <div className="publish-date">
                                        <p className="season-details-team">
                                          2 Events{" "}
                                          <BsDot
                                            style={{
                                              width: "23px",
                                              height: "23px",
                                            }}
                                          />
                                          £2.00
                                          <br />{" "}
                                          <IoFastFoodOutline
                                            style={{
                                              width: "23px",
                                              height: "23px",
                                            }}
                                          />{" "}
                                          Food and drink included
                                          <br />
                                          <FaCar
                                            style={{
                                              width: "23px",
                                              height: "23px",
                                            }}
                                          />{" "}
                                          Parking pass
                                        </p>
                                      </div>
                                      <button className="main-btn btn-hover w-100 mt-4">
                                        View Details{" "}
                                      </button>
                                    </div>
                                  </div>
                                </div> */}
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {bundlePassbtn === true ? (
                    <>
                      <div className="main-title position-relative mt-4">
                        <h4 className="fs-2 fw-bold">Seasons Passes</h4>
                        {BundleData.length === 0 ? (
                          <>
                            <div className="main-card mt-4">
                              <h3 className="text-center font-16 mt-3 fw-bold">
                                {text_nobundlefound}
                              </h3>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className="row"
                              data-ref="event-filter-content"
                              id="otherPages"
                            >
                              {BundleData.map((e, index) => {
                                return (
                                  <>
                                    <div
                                      className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mix arts concert workshops volunteer sports health_Wellness"
                                      data-ref="mixitup-target"
                                    >
                                      <div className="main-card mt-4">
                                        <div className="event-thumbnail">
                                          <Link
                                            to={"#"}
                                            className="thumbnail-img"
                                          >
                                            <img
                                              src={
                                                e?.url === "" ||
                                                e?.url === undefined
                                                  ? img1
                                                  : e?.url
                                              }
                                              alt=""
                                            />
                                          </Link>
                                        </div>
                                        <div className="event-content">
                                          <Link
                                            to={"#"}
                                            className="event-title event-css"
                                          >
                                            {e?.ticketname}
                                          </Link>

                                          <div className="publish-date">
                                            <p className="season-details-team">
                                              {e.event_count} Events{" "}
                                              <BsDot
                                                style={{
                                                  width: "23px",
                                                  height: "23px",
                                                }}
                                              />
                                              {e?.currency} {e?.price}
                                              <br />{" "}
                                              {e?.isFoodAndBeverages ===
                                              true ? (
                                                <>
                                                  <IoFastFoodOutline
                                                    style={{
                                                      width: "23px",
                                                      height: "23px",
                                                    }}
                                                  />{" "}
                                                  Food and drink included
                                                </>
                                              ) : (
                                                <>
                                                  {" "}
                                                  <IoFastFoodOutline
                                                    style={{
                                                      width: "23px",
                                                      height: "23px",
                                                    }}
                                                  />{" "}
                                                  NA
                                                </>
                                              )}
                                              <br />
                                              {e.isParking === true ? (
                                                <>
                                                  <FaCar
                                                    style={{
                                                      width: "23px",
                                                      height: "23px",
                                                    }}
                                                  />{" "}
                                                  Parking pass (
                                                  {e.ParkingLevel === ""
                                                    ? ""
                                                    : e.ParkingLevel}
                                                  )
                                                </>
                                              ) : (
                                                <>
                                                  <FaCar
                                                    style={{
                                                      width: "23px",
                                                      height: "23px",
                                                    }}
                                                  />{" "}
                                                  NA
                                                </>
                                              )}
                                            </p>
                                          </div>
                                          <button
                                            className="main-btn btn-hover w-100 mt-4"
                                            onClick={() =>
                                              navigate(
                                                `/bundleevent/${e?.bundleticketid}`,
                                                {
                                                  state: {
                                                    bundleticketid:
                                                      e?.bundleticketid,
                                                  },
                                                }
                                              )
                                            }
                                          >
                                            View Details{" "}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {calendarbtn === true ? (
                        <>
                          <h1 className="mt-4">Calendar</h1>
                          <FullCalendar
                            showNonCurrentDates={false}
                            events={events}
                            defaultView="dayGridMonth"
                            plugins={[
                              dayGridPlugin,
                              interactionPlugin,
                              momentPlugin,
                            ]}
                            weekends={true}
                            datesSet={handleDatesSet}
                            eventContent={(eventInfo) => (
                              <div className="calendar-event">
                                <h4>
                                  <span className="calendar-event-title pb-3">
                                    {eventInfo.event.title}
                                  </span>
                                  <br />
                                  <span className="calendar-event-time">
                                    {moment(eventInfo.event.starttime).format(
                                      "hh:mm A"
                                    )}
                                  </span>
                                </h4>
                              </div>
                            )} // Customize event rendering if needed
                          />
                        </>
                      ) : (
                        <>
                          <div className="main-title position-relative mt-4">
                            <h4 className="fs-2 fw-bold">Upcoming Games</h4>
                            <Link to="#" className="view-all-link">
                              View More
                            </Link>
                          </div>
                          <UpcomingEvent />
                          <div className="main-title position-relative mt-5">
                            <h4 className="fs-2 fw-bold">All Games</h4>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex gap-3">
                                <div className="form-control filter-team d-flex">
                                  <MdDateRange className="icon-team" />
                                  <DatePicker
                                    //   showIcon
                                    className="h_40 mx-1 form-control form-control-BR w-100"
                                    selected={startDate}
                                    dateFormat="dd MMM,yyyy"
                                    onChange={(date) => setStartDate(date)}
                                  />
                                </div>
                                <div className="form-control filter-team d-flex">
                                  <IoLocationOutline className="icon-team" />
                                  <input
                                    type="text"
                                    className="h_40 form-control form-control-BR w-100"
                                    name=""
                                    id=""
                                    placeholder="Location"
                                  />
                                </div>
                              </div>
                              <div className="d-flex justify-content-end gap-3">
                                <div className="form-control filter-team d-flex">
                                  <BsFilterLeft className="icon-team" />
                                  <select
                                    name=""
                                    id=""
                                    className="h_40 form-control form-control-BR w-100"
                                  >
                                    <option value=""> Teams</option>
                                  </select>
                                </div>
                                <div className="form-control filter-team d-flex">
                                  <BsFilterLeft className="icon-team" />
                                  <input
                                    type="text"
                                    className="h_40 form-control form-control-BR w-100"
                                    name=""
                                    id=""
                                    placeholder="Calendar View"
                                  />
                                </div>
                                <div className="form-control filter-team d-flex">
                                  <BsFilterLeft className="icon-team" />
                                  <select
                                    name=""
                                    id=""
                                    className="h_40 form-control form-control-BR w-100"
                                  >
                                    <option value=""> Sort</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            {AllEvents.length > 0 &&
                              AllEvents.map((e) => {
                                return (
                                  <>
                                    <div className="new-card-team d-flex justify-content-between  mt-3">
                                      <div className="d-flex">
                                        <img
                                          src={
                                            e.EventImagePath === "path"
                                              ? img1
                                              : e.EventImagePath
                                          }
                                          alt=""
                                          className="card-img-team"
                                        />
                                        <div className="m-3 responsive-team-card">
                                          <h3> {e.EventTitle}</h3>
                                          <span>
                                            {moment
                                              .utc(e?.StartDate)
                                              .format("DD MMM, yyyy")}{" "}
                                            •{" "}
                                            {moment
                                              .utc(e.StartDate)
                                              .format("ddd")}
                                            ,{" "}
                                            {moment
                                              .utc(e?.StartTime)
                                              .format("hh:mm a")}
                                          </span>{" "}
                                          <br />
                                          <span>
                                            {e?.venuedetails[0].venuename} ({" "}
                                            {e?.venuedetails[0].address},{" "}
                                            {e?.venuedetails[0].city},{" "}
                                            {e?.venuedetails[0].state},{" "}
                                            {e?.venuedetails[0].country},{" "}
                                            {e?.venuedetails[0].zipcode})
                                          </span>
                                        </div>
                                      </div>
                                      <button
                                        className="buybtn-team mx-3"
                                        onClick={() =>
                                          navigate(
                                            `/event-details/${e?.event_unique_id}`
                                          )
                                        }
                                      >
                                        Buy Tickets
                                      </button>
                                    </div>
                                  </>
                                );
                              })}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </>
  );
}

export default TeamPage;
