import React, { useEffect, useState } from "react";
import "../LeagueCreate/LeagueCreate.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { RxCross1, RxCross2 } from "react-icons/rx";
import axios from "axios";
import { API_DOMAIN, GlobalConstants } from "../../utils/GlobalConst";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GrChapterNext, GrChapterPrevious } from "react-icons/gr";
import { Box, Divider, Modal } from "@mui/material";
import { IoIosCloseCircleOutline } from "react-icons/io";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function LeagueCreate() {
  const navigate = useNavigate();
  const [openCreateTeamPopup, setOpenCreateTeamPopup] = useState(false);
  const handleOpenCreateTeamPopup = () => setOpenCreateTeamPopup(true);
  const handleCloseCreateTeamPopup = () => [
    setOpenCreateTeamPopup(false),
    setInputFieldsPopup([
      {
        team_id_: uuidv4(),
        teamname: "",
        isteamactive: true,
      },
    ]),
  ];
  const styleTwo = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
  };
  const [leagueName, setLeagueName] = useState("");
  const [leagueuniqueURL, setLeagueuniqueURL] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const filterTime = (time) => {
    const selectedDate = new Date(time);
    return startTime < selectedDate.getTime();
  };
  const [openEdit, setOpenEdit] = useState(false);
  const [text_leaguecreation, setText_leaguecreation] =
    useState("League Creation");
  const [text_createteam, setText_createteam] = useState("Create Team");
  const [text_leagueinfo, setText_leagueinfo] = useState("League Info");
  const [text_leagueteam, setTest_leagueteam] = useState("League Team");
  const [text_seasonschedule, setText_seasonschedule] =
    useState("Season Schedule");
  const [text_gamelist, setText_gamelist] = useState("Game List");
  const [text_leaguename, setText_leaguename] = useState("League Name");
  const [text_enterleaguename, setText_enterleaguename] =
    useState("Enter League Name");
  const [text_leagueadmin, setText_leagueadmin] = useState("League Admin");
  const [text_add, setText_add] = useState("Add");
  const [text_enterleagueadmin, setText_enterleagueadmin] =
    useState("Enter League Admin");
  const [text_next, setText_next] = useState("Next");
  const [text_previous, setText_previous] = useState("Previous");
  const [text_selectteam, setText_selectteam] = useState("Select Team");
  const [text_admin, setText_admin] = useState("Admin");
  const [text_startDate, setText_startDate] = useState("Start Date");
  const [text_endDate, setText_endDate] = useState("End Date");
  const [text_endTime, setText_endTime] = useState("End Time");
  const [text_startTime, setText_startTime] = useState("Start Time");
  const [text_hometeam, setText_hometeam] = useState("Home Team");
  const [text_selhometeam, setText_selhometeam] = useState("Select Home Team");
  const [text_awayteam, setText_awayteam] = useState("Away Team");
  const [text_selawayteam, setText_selawayteam] = useState("Select Away Team");
  const [text_date, setText_date] = useState("Date");
  const [text_venue, setText_venue] = useState("Venue");
  const [text_selvenue, setText_selvenue] = useState("Select Venue");
  const [text_selgame, setText_selgame] = useState("Select Game");
  const [text_save, setText_save] = useState("Save");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_home, setText_home] = useState("Home");
  const [text_name, setText_name] = useState("Name");
  const [text_ph_name, setText_ph_name] = useState("Enter Name");
  const [text_submit, setText_submit] = useState("Submit");
  const [text_addnewteam, setText_addnewteam] = useState("Add New Team");
  const [text_active, setText_active] = useState("Active");
  const [text_orgdashboard, setText_orgdashboard] = useState(
    "Organization Dashboard"
  );
  const [text_addleagueadmin, setText_addleagueadmin] =
    useState("Add League Admin");
  const [text_addleagueteam, setText_addleagueteam] =
    useState("Add League Team");
  const [text_validationteam, setText_validationteam] = useState(
    "Please select first league team"
  );
  const [text_addgame, setText_addgame] = useState("Add Game");
  const [text_addname, setText_addname] = useState("Add Name");

  const [leagueAdminArr, setLeagueAdminArr] = useState([
    {
      userid: uuidv4(),
      leagueadminname: sessionStorage.getItem("useremail"),
    },
  ]);
  const handleChangeLeagueAdmin = (_id, team) => {
    const newleagueAdminArr = leagueAdminArr.map((i) => {
      if (_id === i.userid) {
        i[team.target.name] = team.target.value;
      }
      return i;
    });

    setLeagueAdminArr(newleagueAdminArr);
  };
  const handleAddLeagueAdmin = () => {
    setLeagueAdminArr([
      ...leagueAdminArr,
      {
        userid: uuidv4(),
        leagueadminname: "",
      },
    ]);
  };
  const handleRemoveLeagueAdmin = (userid) => {
    if (leagueAdminArr.length === 1) {
      toast.info("Minimum one admin is required");
    } else {
      const values = [...leagueAdminArr];
      values.splice(
        values.findIndex((value) => value.userid === userid),
        1
      );
      setLeagueAdminArr(values);
    }
  };
  const [inputFields, setInputFields] = useState([
    {
      team_id: uuidv4(),
      team1: "",
    },
  ]);

  //   MainTeam
  const [leagueteams, setLeagueteams] = useState([
    {
      teamid: uuidv4(),
      leagueteame: "",
      admin: [
        {
          userid: uuidv4(),
          adminname: "",
        },
      ],
    },
    {
      teamid: uuidv4(),
      leagueteame: "",
      admin: [
        {
          userid: uuidv4(),
          adminname: "",
        },
      ],
    },
  ]);
  console.log(leagueteams);
  const addTeam = () => {
    setLeagueteams([
      ...leagueteams,
      {
        teamid: uuidv4(),
        leagueteame: "",
        admin: [{ userid: uuidv4(), adminname: "" }],
      },
    ]);
  };
  const addAdmin = (teamIndex) => {
    const updatedTeams = [...leagueteams];
    updatedTeams[teamIndex].admin.push({ userid: uuidv4(), adminname: "" });
    setLeagueteams(updatedTeams);
  };
  const handleTeamChange = (event, teamIndex) => {
    const selectedData = teamList.filter(
      (item) => item.teamid === event.target.value
    );
    const updatedTeams = [...leagueteams];
    updatedTeams[teamIndex].leagueteame = selectedData[0].teamname;
    updatedTeams[teamIndex].admin = selectedData[0].adminlist;
    updatedTeams[teamIndex].teamid = selectedData[0].teamid;
    console.log(updatedTeams);
    console.log(selectedData);
    setLeagueteams(updatedTeams);
  };
  console.log(leagueteams);

  const handleAdminChange = (event, teamIndex, adminIndex) => {
    const { name, value } = event.target;
    const updatedTeams = [...leagueteams];
    updatedTeams[teamIndex].admin[adminIndex][name] = value;
    setLeagueteams(updatedTeams);
    console.log(event, "...", teamIndex);
  };
  const removeAdmin = (teamIndex, adminIndex) => {
    if (leagueteams[teamIndex].admin.length === 1) {
      toast.info("Minimum One Admin is required");
    } else {
      const updatedTeams = [...leagueteams];
      updatedTeams[teamIndex].admin.splice(adminIndex, 1);
      setLeagueteams(updatedTeams);
    }
  };
  const removeTeam = (teamIndex) => {
    if (leagueteams.length === 2) {
      toast.info("Minimum two teams are required");
    } else {
      const updatedTeams = [...leagueteams];
      updatedTeams.splice(teamIndex, 1);
      setLeagueteams(updatedTeams);
    }
  };

  //   Team 2
  //   const [inputFieldsteam2, setInputFieldsteam2] = useState([
  //     {
  //       team_id: uuidv4(),
  //       team2: "",
  //     },
  //   ]);
  //   const handleChangeInputteam2 = (_id, team) => {
  //     const newInputFieldsteam2 = inputFieldsteam2.map((i) => {
  //       if (_id === i.team_id) {
  //         i[team.target.name] = team.target.value;
  //       }
  //       return i;
  //     });

  //     setInputFieldsteam2(newInputFieldsteam2);
  //   };
  //   const handleAddFieldsteam2 = () => {
  //     setInputFieldsteam2([
  //       ...inputFieldsteam2,
  //       {
  //         team_id: uuidv4(),
  //         team2: "",
  //       },
  //     ]);
  //   };
  //   const handleRemoveFieldsteam2 = (team_id) => {
  //     const values = [...inputFieldsteam2];
  //     values.splice(
  //       values.findIndex((value) => value.team_id === team_id),
  //       1
  //     );
  //     setInputFieldsteam2(values);
  //     // }
  //   };

  //   Game List
  const [inputFieldsgame, setInputFieldsgame] = useState([
    {
      game_id: uuidv4(),
      gametype: "",
      home: "",
      away: "",
      startdatetime: new Date(),
      venueid: "",
      venueaddress: "",
      home_teamid: "",
      away_teamid: "",
    },
  ]);
  console.log("game array", inputFieldsgame);
  const handleChangeInputgame = (_id, game) => {
    const newInputFieldsgame = inputFieldsgame.map((i) => {
      if (_id === i.game_id) {
        i[game.target.name] = game.target.value;
      }
      return i;
    });

    setInputFieldsgame(newInputFieldsgame);
  };
  const handleChangehometeamname = (_id, game) => {
    const [teamName, teamId] = game.target.value.split(",");
    const newInputFieldsgame = inputFieldsgame.map((i) => {
      if (_id === i.game_id) {
        i.home = teamName;
      }
      return i;
    });

    setInputFieldsgame(newInputFieldsgame);
  };
  const handleChangehometeamid = (_id, game) => {
    const [teamName, teamId] = game.target.value.split(",");

    const newInputFieldsgame = inputFieldsgame.map((i) => {
      if (_id === i.game_id) {
        i.home_teamid = teamId;
      }
      return i;
    });

    setInputFieldsgame(newInputFieldsgame);
  };
   const handleChangeawayteamname = (_id, game) => {
     const [teamName, teamId] = game.target.value.split(",");
     const newInputFieldsgame = inputFieldsgame.map((i) => {
       if (_id === i.game_id) {
         i.away = teamName;
       }
       return i;
     });

     setInputFieldsgame(newInputFieldsgame);
   };
   const handleChangeawayteamid = (_id, game) => {
     const [teamName, teamId] = game.target.value.split(",");

     const newInputFieldsgame = inputFieldsgame.map((i) => {
       if (_id === i.game_id) {
         i.away_teamid = teamId;
       }
       return i;
     });

     setInputFieldsgame(newInputFieldsgame);
   };
  const handleAddFieldsgame = () => {
    setInputFieldsgame([
      ...inputFieldsgame,
      {
        game_id: uuidv4(),
        gametype: "",
        home: "",
        away: "",
        startdatetime: new Date(),
        venueid: "",
        venueaddress: "",
        home_teamid: "",
        away_teamid: "",
      },
    ]);
  };
  const handleRemoveFieldsgame = (game_id) => {
    const values = [...inputFieldsgame];
    values.splice(
      values.findIndex((value) => value.game_id === game_id),
      1
    );
    setInputFieldsgame(values);
    // }
  };

  // Popup filed
  const [inputFieldsPopup, setInputFieldsPopup] = useState([
    {
      team_id_: uuidv4(),
      teamname: "",
      isteamactive: true,
    },
  ]);
  const handleChangeInputPopup = (_id, team) => {
    const newInputFieldsPopup = inputFieldsPopup.map((i) => {
      if (_id === i.team_id_) {
        i[team.target.name] = team.target.value;
      }
      return i;
    });

    setInputFieldsPopup(newInputFieldsPopup);
  };
  const handleChangeInputPopupCheckbox = (_id, team) => {
    const newInputFieldsPopup = inputFieldsPopup.map((i) => {
      if (_id === i.team_id_) {
        i[team.target.name] = team.target.checked;
      }
      return i;
    });

    setInputFieldsPopup(newInputFieldsPopup);
  };
  const handleAddFieldsPopup = () => {
    setInputFieldsPopup([
      ...inputFieldsPopup,
      {
        team_id_: uuidv4(),
        teamname: "",
        isteamactive: true,
      },
    ]);
  };
  const handleRemoveFieldsPopup = (team_id) => {
    if (inputFieldsPopup.length === 1) {
      toast.info("Please Add Minimum One Team");
    } else {
      const values = [...inputFieldsPopup];
      values.splice(
        values.findIndex((value) => value.team_id_ === team_id),
        1
      );
      setInputFieldsPopup(values);
    }
  };

  const handleModalclose = () => {
    document.getElementById("aboutModal").classList.remove("show");
    document.getElementById("aboutModal").removeAttribute("aria-labelledby");
    document.getElementById("aboutModal").removeAttribute("role");
    document.getElementById("aboutModal").removeAttribute("data-bs-toggle");
    document.getElementById("aboutModal").style.display = "none";
  };
  const createLeagueHanldle = () => {
    var API_URL = API_DOMAIN + "league/add";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      leaguename: leagueName,
      leagueuniqueURL: leagueuniqueURL,
      leagueadmins: leagueAdminArr,
      leagueteams: leagueteams,
      gamelist: inputFieldsgame,
      //change to home and away
      seasonalschedule: [
        {
          startdate: startDate,
          starttime: startTime,
          enddate: endDate,
          endtime: endTime,
        },
      ],
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create league", response);
        if (response.data.status === true) {
          toast.success(response.data.message);
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const getLeagueDetails = () => {
    var API_URL = API_DOMAIN + "league/getall";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("league details", response);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    getLeagueDetails();
    getTeamDetails();
    getVenueDetails();
  }, []);
  const [activeTab, setActiveTab] = useState(1);

  const showTab = (n) => {
    let newTab = activeTab + n;

    // Wrap around to the first tab if reached the end
    if (newTab > 4) {
      newTab = 1;
    } else if (newTab < 1) {
      newTab = 4;
    }

    setActiveTab(newTab);
  };
  const CreateTeamHandle = () => {
    var API_URL = API_DOMAIN + "team/add";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    var data = {
      team: inputFieldsPopup,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then(function (response) {
        console.log("create Team", response);
        if (response.data.status === true) {
          handleCloseCreateTeamPopup();
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [teamList, setTeamList] = useState([]);
  const getTeamDetails = () => {
    var API_URL = API_DOMAIN + "team/selectall";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("Team details", response.data.data);
        setTeamList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const [venueList, setVenueList] = useState([]);

  const getVenueDetails = () => {
    var API_URL = API_DOMAIN + "venue/venue";

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        console.log("venue", response);
        setVenueList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_leaguecreation(
        xpath.select1("/resources/string[@name='text_leaguecreation']", doc)
          .firstChild.data
      );
      setText_createteam(
        xpath.select1("/resources/string[@name='text_createteam']", doc)
          .firstChild.data
      );
      setText_leagueinfo(
        xpath.select1("/resources/string[@name='text_leagueinfo']", doc)
          .firstChild.data
      );
      setTest_leagueteam(
        xpath.select1("/resources/string[@name='text_leagueteam']", doc)
          .firstChild.data
      );
      setText_seasonschedule(
        xpath.select1("/resources/string[@name='text_seasonschedule']", doc)
          .firstChild.data
      );
      setText_gamelist(
        xpath.select1("/resources/string[@name='text_gamelist']", doc)
          .firstChild.data
      );
      setText_leaguename(
        xpath.select1("/resources/string[@name='text_leaguename']", doc)
          .firstChild.data
      );
      setText_enterleaguename(
        xpath.select1("/resources/string[@name='text_enterleaguename']", doc)
          .firstChild.data
      );
      setText_leagueadmin(
        xpath.select1("/resources/string[@name='text_leagueadmin']", doc)
          .firstChild.data
      );
      setText_add(
        xpath.select1("/resources/string[@name='text_add']", doc).firstChild
          .data
      );
      setText_enterleagueadmin(
        xpath.select1("/resources/string[@name='text_enterleagueadmin']", doc)
          .firstChild.data
      );
      setText_next(
        xpath.select1("/resources/string[@name='text_next']", doc).firstChild
          .data
      );
      setText_previous(
        xpath.select1("/resources/string[@name='text_previous']", doc)
          .firstChild.data
      );
      setText_selectteam(
        xpath.select1("/resources/string[@name='text_selectteam']", doc)
          .firstChild.data
      );
      setText_admin(
        xpath.select1("/resources/string[@name='text_admin']", doc).firstChild
          .data
      );
      setText_startDate(
        xpath.select1("/resources/string[@name='text_startDate']", doc)
          .firstChild.data
      );
      setText_endDate(
        xpath.select1("/resources/string[@name='text_endDate']", doc).firstChild
          .data
      );
      setText_endTime(
        xpath.select1("/resources/string[@name='text_endTime']", doc).firstChild
          .data
      );
      setText_startTime(
        xpath.select1("/resources/string[@name='text_startTime']", doc)
          .firstChild.data
      );
      setText_hometeam(
        xpath.select1("/resources/string[@name='text_hometeam']", doc)
          .firstChild.data
      );
      setText_selhometeam(
        xpath.select1("/resources/string[@name='text_selhometeam']", doc)
          .firstChild.data
      );
      setText_awayteam(
        xpath.select1("/resources/string[@name='text_awayteam']", doc)
          .firstChild.data
      );
      setText_selawayteam(
        xpath.select1("/resources/string[@name='text_selawayteam']", doc)
          .firstChild.data
      );
      setText_venue(
        xpath.select1("/resources/string[@name='text_venue']", doc).firstChild
          .data
      );
      setText_date(
        xpath.select1("/resources/string[@name='text_date']", doc).firstChild
          .data
      );
      setText_selvenue(
        xpath.select1("/resources/string[@name='text_selvenue']", doc)
          .firstChild.data
      );
      setText_selgame(
        xpath.select1("/resources/string[@name='text_selgame']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_home(
        xpath.select1("/resources/string[@name='text_home']", doc).firstChild
          .data
      );
      setText_name(
        xpath.select1("/resources/string[@name='text_name']", doc).firstChild
          .data
      );
      setText_ph_name(
        xpath.select1("/resources/string[@name='text_ph_name']", doc).firstChild
          .data
      );
      setText_submit(
        xpath.select1("/resources/string[@name='text_submit']", doc).firstChild
          .data
      );
      setText_addnewteam(
        xpath.select1("/resources/string[@name='text_addnewteam']", doc)
          .firstChild.data
      );
      setText_active(
        xpath.select1("/resources/string[@name='text_active']", doc).firstChild
          .data
      );
      setText_orgdashboard(
        xpath.select1("/resources/string[@name='text_orgdashboard']", doc)
          .firstChild.data
      );
      setText_addleagueadmin(
        xpath.select1("/resources/string[@name='text_addleagueadmin']", doc)
          .firstChild.data
      );
      setText_addleagueteam(
        xpath.select1("/resources/string[@name='text_addleagueteam']", doc)
          .firstChild.data
      );
      setText_validationteam(
        xpath.select1("/resources/string[@name='text_validationteam']", doc)
          .firstChild.data
      );
      setText_addgame(
        xpath.select1("/resources/string[@name='text_addgame']", doc).firstChild
          .data
      );
      setText_addname(
        xpath.select1("/resources/string[@name='text_addname']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />\
      <div
        className="Create-league-wrapper eventdetail-nightmod"
        id="otherPages"
      >
        <div className="breadcrumb-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-10">
                <div className="barren-breadcrumb">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">{text_home}</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/org-dashboard">{text_orgdashboard}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {text_leaguecreation}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="event-dt-block p-80">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <div className="main-title text-center">
                  <h3>{text_leaguecreation}</h3>
                </div>
              </div>
              <div className="main-card">
                <div className="p-4 bp-form main-form">
                  <div className="profile-tabs">
                    <div className="row p-4">
                      <div className="col-lg-12 col-md-12 text-end"></div>
                    </div>
                    <ul
                      className="nav nav-pills nav-fill p-2 garren-line-tab "
                      id="myTab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeTab === 1 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(1)}
                        >
                          {text_leagueinfo}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeTab === 2 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(2)}
                        >
                          {text_leagueteam}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeTab === 3 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(3)}
                        >
                          {text_seasonschedule}
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            activeTab === 4 ? "active" : ""
                          }`}
                          onClick={() => setActiveTab(4)}
                        >
                          {text_gamelist}
                        </Link>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      {activeTab === 1 ? (
                        <>
                          <div className="nav my-event-tabs mt-4">
                            <div className="row w-100 p-4">
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_leaguename}
                                  </label>
                                  <input
                                    className="form-control h_50 league-input inputleaguenight"
                                    type="text"
                                    placeholder={text_enterleaguename}
                                    onChange={(e) =>
                                      setLeagueName(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              {/* <div className="col-lg-6 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <label className="form-label fs-16">
                                    {text_leagueuniqueurl}
                                  </label>
                                  <input
                                    className="form-control h_50 w-75"
                                    type="text"
                                    onChange={(e) =>
                                      setLeagueuniqueURL(e.target.value)
                                    }
                                    placeholder={text_urlforleague}
                                  />
                                </div>
                              </div> */}
                              <div className="col-lg-6 col-md-12">
                                <div className="form-group border_bottom-create profile-night-form pb_30">
                                  <div className="leagueinputSty mb-2">
                                    <label className="form-label fs-16">
                                      {text_leagueadmin}
                                    </label>
                                  </div>
                                  {leagueAdminArr.map((adminlist) => {
                                    return (
                                      <>
                                        <div className="d-flex gap-2 mt-2">
                                          <input
                                            className="form-control inputleaguenight h_50 league-input mb-3"
                                            type="text"
                                            placeholder={text_enterleagueadmin}
                                            value={adminlist.leagueadminname}
                                            name="leagueadminname"
                                            onChange={(team) =>
                                              handleChangeLeagueAdmin(
                                                adminlist.userid,
                                                team
                                              )
                                            }
                                          />
                                          <button
                                            onClick={() =>
                                              handleRemoveLeagueAdmin(
                                                adminlist.userid
                                              )
                                            }
                                            className="removeBtnleague"
                                          >
                                            <RxCross2 className="crossIcon" />
                                          </button>
                                        </div>
                                      </>
                                    );
                                  })}
                                  <div className="d-flex gap-1">
                                    <button
                                      className="ADDBtn"
                                      onClick={handleAddLeagueAdmin}
                                    >
                                      +
                                    </button>{" "}
                                    <p>{text_addleagueadmin}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="d-flex justify-content-end">
                                <button
                                  onClick={() => showTab(1)}
                                  className="saveBtn"
                                >
                                  {text_next} &nbsp;
                                  <GrChapterNext />
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {activeTab === 2 ? (
                            <>
                              <div className="nav my-event-tabs mt-4">
                                <div className="row w-100 p-4">
                                  {leagueteams.map((team, teamIndex) => {
                                    return (
                                      <>
                                        <div
                                          className="col-lg-6 col-md-12"
                                          key={teamIndex}
                                        >
                                          <div className="form-group border_bottom-create profile-night-form pb_30">
                                            <div className="leagueinputSty mb-3">
                                              <label className="form-label  fs-16 mt-3">
                                                {text_leagueteam}
                                              </label>
                                              <div className="">
                                                <button
                                                  className="removeBtnleague"
                                                  onClick={() =>
                                                    removeTeam(teamIndex)
                                                  }
                                                >
                                                  <RxCross2 className="crossIcon" />
                                                </button>
                                              </div>
                                            </div>

                                            <div>
                                              {console.log("object", team)}

                                              <select
                                                className="form-control h_50 league-input inputleaguenight"
                                                // type="text"
                                                // name="leagueteame"
                                                // value={team.leagueteame}
                                                defaultValue={team.leagueteame}
                                                onChange={(e) =>
                                                  handleTeamChange(e, teamIndex)
                                                }
                                              >
                                                <option
                                                  value=""
                                                  disabled
                                                  selected
                                                >
                                                  {text_selectteam}
                                                </option>

                                                {teamList.map((data) => {
                                                  return (
                                                    <>
                                                      <option
                                                        value={data?.teamid}
                                                      >
                                                        {data.teamname}
                                                      </option>
                                                    </>
                                                  );
                                                })}
                                              </select>
                                            </div>
                                            {team.leagueteame !== "" ? (
                                              <>
                                                <div className="mt-3 gap-4">
                                                  <div className="leagueinputSty">
                                                    <label className="form-label fs-16">
                                                      {text_admin}
                                                    </label>
                                                  </div>
                                                  {team.admin.map(
                                                    (admin, adminIndex) => {
                                                      return (
                                                        <>
                                                          <div className="d-flex gap-2 mt-2">
                                                            {/* {leagueteams.map((val,i)=>{
                                                          return (
                                                            <> */}
                                                            <input
                                                              className="form-control h_50 league-input inputleaguenight"
                                                              type="text"
                                                              name="adminname"
                                                              value={
                                                                admin.email
                                                              }
                                                              // onChange={(e) =>
                                                              //   handleAdminChange(
                                                              //     e,
                                                              //     teamIndex,
                                                              //     adminIndex
                                                              //   )
                                                              // }
                                                            />
                                                            {/* </>
                                                          )
                                                        })} */}

                                                            {console.log(
                                                              leagueteams
                                                            )}
                                                            <button
                                                              onClick={() =>
                                                                removeAdmin(
                                                                  teamIndex,
                                                                  adminIndex
                                                                )
                                                              }
                                                              className="removeBtnleague"
                                                            >
                                                              <RxCross2 className="crossIcon" />
                                                            </button>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                  {/* <div className="d-flex gap-1 mt-2">
                                                <button
                                                  className="ADDBtn"
                                                  onClick={() =>
                                                    addAdmin(teamIndex)
                                                  }
                                                >
                                                  +
                                                </button>{" "}
                                                <p>Add Admin</p>
                                              </div> */}
                                                </div>
                                              </>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                                  <div className="col-lg-12 col-md-12 text-start mb-4">
                                    <div className="d-flex gap-1">
                                      <button
                                        className="ADDBtn"
                                        onClick={addTeam}
                                      >
                                        +
                                      </button>{" "}
                                      <p>{text_addleagueteam}</p>
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between gap-2">
                                    <button
                                      onClick={() => showTab(-1)}
                                      className="cancelBtn league-cancel-btn"
                                    >
                                      <GrChapterPrevious /> &nbsp;{" "}
                                      {text_previous}
                                    </button>
                                    <button
                                      onClick={() => showTab(1)}
                                      className="saveBtn"
                                    >
                                      {text_next} &nbsp;
                                      <GrChapterNext />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {activeTab === 3 ? (
                                <>
                                  <div className="nav my-event-tabs mt-4">
                                    <div className="row w-100 p-4">
                                      <div className="col-lg-6 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form pb_30">
                                          <label className="form-label fs-16">
                                            {text_startDate}
                                          </label>
                                          <DatePicker
                                            className="form-control dateInputSty h_50 league-date-input inputleaguenight"
                                            minDate={new Date()}
                                            selected={startDate}
                                            dateFormat="dd MMM,yyyy"
                                            onChange={(date) => [
                                              setStartDate(date),
                                              setEndDate(date),
                                            ]}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form pb_30">
                                          <label className="form-label fs-16">
                                            {text_endDate}
                                          </label>
                                          <DatePicker
                                            className="form-control dateInputSty h_50 league-date-input inputleaguenight"
                                            selected={endDate}
                                            minDate={startDate}
                                            dateFormat="dd MMM,yyyy"
                                            onChange={(date) =>
                                              setEndDate(date)
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form pb_30">
                                          <label className="form-label fs-16">
                                            {text_startTime}
                                          </label>
                                          <DatePicker
                                            className="form-control dateInputSty h_50 league-date-input inputleaguenight"
                                            selected={startTime}
                                            onChange={(date) => [
                                              setStartTime(date),
                                              setEndTime(date),
                                            ]}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={10}
                                            dateFormat="h:mm aa"
                                            // filterTime={filterStartTime}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-12">
                                        <div className="form-group border_bottom-create profile-night-form pb_30">
                                          <label className="form-label fs-16">
                                            {text_endTime}
                                          </label>
                                          <DatePicker
                                            className="form-control dateInputSty h_50 league-date-input inputleaguenight"
                                            selected={endTime}
                                            onChange={(date) =>
                                              setEndTime(date)
                                            }
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={10}
                                            dateFormat="h:mm aa"
                                            filterTime={filterTime}
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between gap-2">
                                        <button
                                          onClick={() => showTab(-1)}
                                          className="cancelBtn league-cancel-btn"
                                        >
                                          <GrChapterPrevious />
                                          &nbsp; {text_previous}
                                        </button>
                                        <button
                                          onClick={() => showTab(1)}
                                          className="saveBtn"
                                        >
                                          {text_next} &nbsp;
                                          <GrChapterNext />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="nav my-event-tabs mt-4">
                                    <div className="row w-100 p-4">
                                      <div className="gatezonebox mb-3">
                                        {inputFieldsgame.map((inputField) => {
                                          return (
                                            <>
                                              <div className=" seatingArrengmentBox">
                                                <div className=" ">
                                                  <h5>{text_hometeam}</h5>

                                                  <select
                                                    type="text"
                                                    value={inputField.home}
                                                    name="home"
                                                    id=""
                                                    className="form-control h_40 venue-input inputleaguenight"
                                                    onChange={(game) => [
                                                      handleChangehometeamname(
                                                        inputField.game_id,
                                                        game
                                                      ),
                                                      console.log(
                                                        game.target.value
                                                      ),
                                                      handleChangehometeamid(
                                                        inputField.game_id,
                                                        game
                                                      ),
                                                    ]}
                                                  >
                                                    <option
                                                      value=""
                                                      disabled
                                                      selected
                                                    >
                                                      {text_selhometeam}
                                                    </option>
                                                    {leagueteams.length > 0 ? (
                                                      <>
                                                        {leagueteams.map(
                                                          (data) => {
                                                            return (
                                                              <>
                                                                <option
                                                                  value={[
                                                                    data?.leagueteame,
                                                                    data?.teamid,
                                                                  ]}
                                                                >
                                                                  {
                                                                    data?.leagueteame
                                                                  }
                                                                </option>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <option disabled>
                                                          {text_validationteam}
                                                        </option>
                                                      </>
                                                    )}
                                                  </select>
                                                </div>
                                                <div className=" ">
                                                  <h5>{text_awayteam}</h5>

                                                  <select
                                                    type="text"
                                                    value={inputField.away}
                                                    name="away"
                                                    id=""
                                                    className="form-control h_40 venue-input inputleaguenight"
                                                    onChange={(game) =>
                                                      [handleChangeawayteamname(
                                                        inputField.game_id,
                                                        game
                                                      ),
                                                      console.log(
                                                        game.target.value
                                                      ),
                                                      handleChangeawayteamid(
                                                        inputField.game_id,
                                                        game
                                                      )]
                                                    }
                                                  >
                                                    <option
                                                      value=""
                                                      disabled
                                                      selected
                                                    >
                                                      {text_selawayteam}
                                                    </option>
                                                    {leagueteams.length > 0 ? (
                                                      <>
                                                        {leagueteams.map(
                                                          (data) => {
                                                            return (
                                                              <>
                                                                <option
                                                                  value={[
                                                                    data?.leagueteame,
                                                                    data?.teamid,
                                                                  ]}
                                                                >
                                                                  {
                                                                    data?.leagueteame
                                                                  }
                                                                </option>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <option disabled>
                                                          Please select first
                                                          league team
                                                        </option>
                                                      </>
                                                    )}
                                                  </select>
                                                </div>
                                                <div className=" ">
                                                  <h5>{text_date}</h5>
                                                  <DatePicker
                                                    className="form-control dateInputSty inputleaguenight"
                                                    minDate={new Date()}
                                                    selected={
                                                      inputField.startdatetime
                                                    }
                                                    dateFormat="dd MMM,yyyy"
                                                    value={
                                                      inputField.startdatetime
                                                    }
                                                    name="startdatetime"
                                                    id=""
                                                    onChange={(game) =>
                                                      handleChangeInputgame(
                                                        inputField.game_id,
                                                        game
                                                      )
                                                    }
                                                  />
                                                </div>
                                                {/* <div className=" ">
                                        <h5>Time</h5>
                                        <input
                                          type="text"
                                          value={inputField.time}
                                          name="time"
                                          id=""
                                          className="form-control h_40 venue-input"
                                          onChange={(game) =>
                                            handleChangeInputgame(
                                              inputField.game_id,
                                              game
                                            )
                                          }
                                        />
                                      </div> */}
                                                <div className=" ">
                                                  <h5>{text_venue}</h5>

                                                  <select
                                                    type="text"
                                                    value={
                                                      inputField.venueaddress
                                                    }
                                                    name="venueaddress"
                                                    id=""
                                                    className="form-control h_40 venue-input inputleaguenight"
                                                    onChange={(game) =>
                                                      handleChangeInputgame(
                                                        inputField.game_id,
                                                        game
                                                      )
                                                    }
                                                  >
                                                    <option selected disabled>
                                                      {text_selvenue}
                                                    </option>
                                                    {venueList.map((v) => {
                                                      return (
                                                        <>
                                                          <option
                                                            value={v.venuename}
                                                          >
                                                            {v.venuename}
                                                          </option>
                                                        </>
                                                      );
                                                    })}
                                                  </select>
                                                </div>
                                                <div className=" ">
                                                  <h5>{text_selgame}</h5>
                                                  <select
                                                    type="text"
                                                    value={inputField.gametype}
                                                    name="gametype"
                                                    id=""
                                                    className="form-control h_40 venue-input inputleaguenight"
                                                    onChange={(game) =>
                                                      handleChangeInputgame(
                                                        inputField.game_id,
                                                        game
                                                      )
                                                    }
                                                  >
                                                    <option
                                                      value=""
                                                      selected
                                                      disabled
                                                    >
                                                      {text_selgame}
                                                    </option>
                                                    <option value="leaguegame">
                                                      league game
                                                    </option>
                                                    <option value="KnockOut">
                                                      KnockOut
                                                    </option>
                                                    <option value="Final">
                                                      Championship game/Final
                                                    </option>
                                                  </select>
                                                </div>
                                                <div className="">
                                                  <button
                                                    onClick={() =>
                                                      handleRemoveFieldsgame(
                                                        inputField.game_id
                                                      )
                                                    }
                                                    className="removeBtnVenue"
                                                  >
                                                    <RxCross2 className="crossIcon" />
                                                  </button>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        })}
                                      </div>
                                      <div className="justify-content-start d-flex gap-1 mb-2">
                                        <button
                                          onClick={handleAddFieldsgame}
                                          className="ADDBtn"
                                        >
                                          +
                                        </button>{" "}
                                        <p>{text_addgame}</p>
                                      </div>
                                      <div className="d-flex justify-content-between gap-2">
                                        <button
                                          onClick={() => showTab(-1)}
                                          className="cancelBtn league-cancel-btn"
                                        >
                                          <GrChapterPrevious />
                                          &nbsp; {text_previous}
                                        </button>
                                        <button
                                          onClick={createLeagueHanldle}
                                          className="saveBtn"
                                        >
                                          {text_save}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openCreateTeamPopup}
        onClose={handleCloseCreateTeamPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="Upload-image-Modal"
      >
        <Box
          sx={styleTwo}
          id="header-registermodal"
          className="Upload-image-event"
        >
          <div>
            <div className="d-flex justify-content-end">
              <IoIosCloseCircleOutline
                className="closeIcon-venue"
                onClick={() => [handleCloseCreateTeamPopup()]}
              />
            </div>
            <h3 className="popup-main-title">{text_addnewteam}</h3>
            <Divider />
            <div>
              <div className="row">
                <div className="col-lg-12 col-md-12  mt-3">
                  <div className="d-flex justify-content-end gap-1">
                    <button className="ADDBtn" onClick={handleAddFieldsPopup}>
                      +
                    </button>{" "}
                    <p>{text_addname}</p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 createteamscroll">
                  {inputFieldsPopup.map((inputField) => {
                    return (
                      <>
                        <div className="form-group mt-4">
                          <label className="form-label">{text_name}</label>
                          <div className="d-flex gap-5">
                            <input
                              className="form-control h_40 "
                              type="text"
                              value={inputField.teamname}
                              placeholder={text_ph_name}
                              name="teamname"
                              id=""
                              onChange={(game) =>
                                handleChangeInputPopup(
                                  inputField.team_id_,
                                  game
                                )
                              }
                            />
                            <div className="d-flex gap-2">
                              <input
                                type="checkbox"
                                className="mb-2"
                                value={inputField.isteamactive}
                                checked={inputField.isteamactive}
                                name="isteamactive"
                                id=""
                                onChange={(game) =>
                                  handleChangeInputPopupCheckbox(
                                    inputField.team_id_,
                                    game
                                  )
                                }
                              />
                              <label className="form-label mt-2">
                                {text_active}
                              </label>
                            </div>
                            <div className="d-flex gap-2">
                              <button
                                onClick={() =>
                                  handleRemoveFieldsPopup(inputField.team_id_)
                                }
                                className="removeBtnleague"
                              >
                                <RxCross2 className="crossIcon" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>

              <div className="d-flex gap-2 justify-content-center mb-3 mt-3">
                <button
                  type="button"
                  className="co-main-btn min-width"
                  data-bs-target="#aboutModal"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal-org"
                  onClick={() => handleCloseCreateTeamPopup()}
                >
                  {text_cancel}
                </button>
                <button
                  type="button"
                  className="main-btn min-width btn-hover h_40 profile-update-btn"
                  onClick={CreateTeamHandle}
                >
                  {text_submit}
                </button>

                {/* </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Footer />
      <ToastContainer />{" "}
    </>
  );
}

export default LeagueCreate;
