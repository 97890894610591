import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import OrgDashboardSidebar from "./OrgDashboardSidebar";
import { toast } from "react-toastify";
import { API_DOMAIN } from "../../utils/GlobalConst";
import axios from "axios";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

function OrgAllLeague() {
  const navigate = useNavigate();
  const [allLeagueData, setAllLeagueData] = useState([]);
  const [nextToken, setNextToken] = useState("");
  const [hasMoreLeagues, setHasMoreLeagues] = useState("");
  const Data = [
    {
      leaguename: "ABA-Liga",
    },
    {
      leaguename: "League-1",
    },
    {
      leaguename: "League-2",
    },
  ];
  const [leagueAdmin, setLeagueAdmin] = useState([]);
  const getAllLeague = () => {
    var API_URL = API_DOMAIN + "league/getall/createdby";
    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    axios
      .get(API_URL, headerConfig)
      .then((response, i) => {
        console.log("all leagues ", response.data.data);

        var res = response.data.data;
        console.log(res);

        setAllLeagueData(res);
        var admin = res[0]?.leagueadmins;
        console.log(admin);
        setLeagueAdmin(admin);

        // setNextToken(res.nexttoken);
        // setHasMoreLeagues(res.hasMoreLeagues);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };
  // const getAllLeague = () => {
  //   var API_URL = API_DOMAIN + "league/getall/withpagination?limit=10";
  //   let headerConfig = {
  //     headers: {
  //       authorization: "bearer " + sessionStorage.getItem("token"),
  //     },
  //   };
  //   axios
  //     .get(API_URL, headerConfig)
  //     .then((response) => {
  //       console.log("all leagues ", response.data.data.league);

  //       var res = response.data.data;
  //       console.log(res.leaguename);

  //       setAllLeagueData(res.league);

  //       setNextToken(res.nexttoken);
  //       setHasMoreLeagues(res.hasMoreLeagues);
  //     })
  //     .catch((error) => {
  //       toast.error(error.response.data.message);
  //       if (error.response.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         setTimeout(() => {
  //           navigate("/");
  //         }, 1000);
  //       } else {
  //         toast.error(error.response.data.message);
  //       }
  //     });
  // };
  useEffect(() => {
    getAllLeague();
  }, []);
  const getMoreLeague = (nextToken, D) => {
    var API_URL =
      API_DOMAIN +
      `league/getall/withpagination?limit=10&nexttoken=${nextToken}`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var res = response.data.data;
        console.log(res.league);

        setAllLeagueData(res.league);

        setNextToken(res.nexttoken);
        setHasMoreLeagues(res.hasMoreLeagues);
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const ActivateTeam = (leagueid) => {
    var API_URL = API_DOMAIN + `league/status/update`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      leagueid: leagueid,
      isleagueactive: true,
    };
    console.log(leagueid);

    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.status === true) {
          getAllLeague();
        }
        toast.info("Team Activated Successfully");
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };
  const DeActivateTeam = (leagueid) => {
    var API_URL = API_DOMAIN + `league/status/update`;

    let headerConfig = {
      headers: {
        authorization: "bearer " + sessionStorage.getItem("token"),
      },
    };
    let data = {
      leagueid: leagueid,
      isleagueactive: false,
    };

    axios
      .put(API_URL, data, headerConfig)
      .then(function (response) {
        console.log(response);
        if (response.data.status === true) {
          getAllLeague();
        }
        toast.info("Team Deactivated Successfully");
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.error(error.response.data.message);
        }
      })
      .then(function () {});
  };

  return (
    <>
      <Header />
      <OrgDashboardSidebar />
      <div id="otherPages">
        <div className="wrapper wrapper-body" id="wrapper-body">
          <div className="dashboard-body">
            <h2 className="text-center text-decoration-underline">
              <b>All League</b>
            </h2>
            <div className="container-fluid">
              <div className="col-md-12">
                <div className="conversion-setup">
                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="overview-tab"
                      role="tabpanel"
                    >
                      <div className="table-card mt-2">
                        <div className="main-table">
                          <div className="table-responsive">
                            <table className="table">
                              <thead className="thead-dark">
                                <tr>
                                  <th>Sr No.</th>
                                  <th scope="col">League Name</th>
                                  <th scope="col">League Admin</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {allLeagueData.length > 0 &&
                                  allLeagueData.map((v, index) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{v.leaguename}</td>

                                          <td>
                                            <ul className="admin-list-css">
                                              {v.leagueadmins.map((i) => {
                                                return (
                                                  <>
                                                    <li>{i.leagueadminname}</li>
                                                  </>
                                                );
                                              })}
                                            </ul>
                                            {/* {leagueAdmin.length > 0 &&
                                              leagueAdmin.map((v, index) => {
                                                return <></>;
                                              })} */}
                                          </td>

                                          <td>
                                            {v?.active === true ? (
                                              <>
                                                {console.log(v.isleagueactive)}
                                                <button
                                                  className="teamActiveSty"
                                                  onClick={() =>
                                                    DeActivateTeam(v.leagueid)
                                                  }
                                                >
                                                  Active
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                <button
                                                  className="teamDeactiveSty"
                                                  onClick={() =>
                                                    ActivateTeam(v.leagueid)
                                                  }
                                                >
                                                  Deactive
                                                </button>
                                              </>
                                            )}
                                            {/* <button className="teamActiveSty">
                                              Active
                                            </button> */}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                              </tbody>
                            </table>

                            {hasMoreLeagues === true ? (
                              <>
                                <div className="browse-btn">
                                  <button
                                    onClick={() =>
                                      getMoreLeague(nextToken, allLeagueData)
                                    }
                                    className="main-btn btn-hover "
                                  >
                                    Next
                                  </button>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrgAllLeague;
