import React, { useState, useEffect } from "react";
import "../HomePage/HomePage.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import AllEventCard from "../AllEvent/AllEventCard";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { AllEventCategory, AllWeekLink } from "../../utils/data";
import { FaArrowRight } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { GlobalConstants } from "../../utils/GlobalConst";
import "react-toastify/dist/ReactToastify.css";
import venueEventsImg from "../../assets/img/icons/venue-events.png";
import webinarImg from "../../assets/img/icons/webinar.png";
import trainingImg from "../../assets/img/icons/training-workshop.png";
import onlineClassImg from "../../assets/img/icons/online-class.png";
import talkShowImg from "../../assets/img/icons/talk-show.png";
import sponserImg1 from "../../assets/img/icons/sponsor-1.png";
import sponserImg2 from "../../assets/img/icons/sponsor-2.png";
import sponserImg3 from "../../assets/img/icons/sponsor-3.png";
import sponserImg4 from "../../assets/img/icons/sponsor-4.png";
import sponserImg5 from "../../assets/img/icons/sponsor-5.png";
import sponserImg6 from "../../assets/img/icons/sponsor-6.png";
import sponserImg7 from "../../assets/img/icons/sponsor-7.png";
import featurIcon1 from "../../assets/img/icons/feature-icon-1.png";
import featurIcon2 from "../../assets/img/icons/feature-icon-2.png";
import featurIcon3 from "../../assets/img/icons/feature-icon-3.png";
import PopularEvent from "../AllEvent/PopularEvent";
import UpcomingEvent from "../AllEvent/UpcomingEvent";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function HomePage() {
  const [text_info_please_login, setText_info_please_login] =
    useState("Please Login First");
  const [text_loginAsOrg_info, setText_loginAsOrg_info] = useState(
    "Please Login As An Organization To Create Event!"
  );
  const [text_activationPending, setText_activationPending] =
    useState("Activation Pending");
  const [text_exploreevent, setText_exploreevent] = useState("Explore Events");
  const [text_browseeve, setText_browseeve] = useState("Browse Events");
  const [text_partnerwithus, setText_partnerwithus] =
    useState("Partner with Us");
  const [test_foreventatt, setText_foreventatt] = useState(
    "For Event Attendees"
  );
  const [text_foreventorg, setText_foreventorg] = useState(
    "For Event Organizers"
  );
  const [text_noticehomepage, setText_noticehomepage] = useState(
    "Welcome to TiQ8.com "
  );
  const [text_noticehomepage1, setText_noticehomepage1] = useState(
    "Revolutionizing Digital Ticketing for Sports and Beyond."
  );
  const [text_noticehomepage2, setText_noticehomepage2] = useState(
    "Elevate Your Event Experience"
  );
  const [text_noticehomepage3, setText_noticehomepage3] = useState(
    "Power Your Events, Engage Your Audience"
  );
  const [text_noticehomepage4, setText_noticehomepage4] = useState(
    "Why Choose TiQ8.com"
  );
  const [text_noticehomepage5, setText_noticehomepage5] = useState(
    "Partner with TiQ8.com to take your events to new heights. Our platform offers not just ticket sales but a comprehensive suite of tools to enhance fan engagement and overall event success."
  );
  const [text_noticehomepage6, setText_noticehomepage6] = useState(
    "Fan Engagement Tools"
  );
  const [text_noticehomepage7, setText_noticehomepage7] = useState(
    "Utilize our innovative fan engagement tools to connect with your audience on a deeper level. From personalized promotions to interactive content, we provide the tools to turn attendees into lifelong fans."
  );
  const [text_noticehomepage8, setText_noticehomepage8] =
    useState("Expand Your Reach");
  const [text_noticehomepage9, setText_noticehomepage9] = useState(
    "Join the TiQ8.com family and expand your event's reach. Benefit from our growing user base and amplify your event's visibility to a broader audience."
  );
  const [text_title1, setText_title1] = useState("Discover Exclusive Events");
  const [text_title2, setText_title2] = useState(
    "Explore a diverse range of events, from thrilling sports matches to captivating performances, all in one place."
  );
  const [text_title3, setText_title3] = useState("Seamless Ticketing");
  const [text_title4, setText_title4] = useState(
    "Purchase tickets effortlessly and securely through our user-friendly platform"
  );
  const [text_title5, setText_title5] = useState("Unlock Digital Experiences");
  const [text_title6, setText_title6] = useState(
    "Each ticket opens the door to a world of personalized digital content, including behind-the-scenes access, virtual meet-and-greets, and more."
  );
  const [text_title7, setText_title7] = useState("Memorable Journeys Await");
  const [text_title8, setText_title8] = useState(
    "Elevate your event experience with TiQ8.com, where every ticket comes with an invitation to an unforgettable journey."
  );

  const navigate = useNavigate();
  const sliderDetails = [
    {
      img: venueEventsImg,
      title: <>{text_title1}</>,
      para: <>{text_title2}</>,
    },
    {
      img: webinarImg,
      title: <>{text_title3}</>,
      para: <>{text_title4}</>,
    },
    {
      img: trainingImg,
      title: <>{text_title5}</>,
      para: <>{text_title6}</>,
    },
    {
      img: onlineClassImg,
      title: <>{text_title7}</>,
      para: <>{text_title8}</>,
    },
  ];

  const createEventHandler = () => {
    if (sessionStorage.getItem("token") === null) {
      toast.info(<>{text_info_please_login}</>);
    } else if (
      sessionStorage.getItem("isOrg") === "false" ||
      sessionStorage.getItem("isOrg") === "undefined"
    ) {
      console.log("in else if");
      toast.info(<>{text_loginAsOrg_info}</>);
    } else if (
      sessionStorage.getItem("isOrg") === "true" &&
      sessionStorage.getItem("isOrgActive") === "false"
    ) {
      console.log("in else else if");

      toast.info(<>{text_activationPending}</>);
    } else {
      navigate("/event-type");
    }
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_loginAsOrg_info(
        xpath.select1("/resources/string[@name='text_loginAsOrg_info']", doc)
          .firstChild.data
      );
      setText_info_please_login(
        xpath.select1("/resources/string[@name='text_info_please_login']", doc)
          .firstChild.data
      );
      setText_exploreevent(
        xpath.select1("/resources/string[@name='text_exploreevent']", doc)
          .firstChild.data
      );
      setText_browseeve(
        xpath.select1("/resources/string[@name='text_browseeve']", doc)
          .firstChild.data
      );
      setText_partnerwithus(
        xpath.select1("/resources/string[@name='text_partnerwithus']", doc)
          .firstChild.data
      );
      setText_foreventatt(
        xpath.select1("/resources/string[@name='test_foreventatt']", doc)
          .firstChild.data
      );
      setText_foreventorg(
        xpath.select1("/resources/string[@name='text_foreventorg']", doc)
          .firstChild.data
      );
      setText_activationPending(
        xpath.select1("/resources/string[@name='text_activationPending']", doc)
          .firstChild.data
      );
      setText_noticehomepage(
        xpath.select1("/resources/string[@name='text_noticehomepage']", doc)
          .firstChild.data
      );
      setText_noticehomepage1(
        xpath.select1("/resources/string[@name='text_noticehomepage1']", doc)
          .firstChild.data
      );
      setText_noticehomepage2(
        xpath.select1("/resources/string[@name='text_noticehomepage2']", doc)
          .firstChild.data
      );
      setText_noticehomepage3(
        xpath.select1("/resources/string[@name='text_noticehomepage3']", doc)
          .firstChild.data
      );
      setText_noticehomepage4(
        xpath.select1("/resources/string[@name='text_noticehomepage4']", doc)
          .firstChild.data
      );
      setText_noticehomepage5(
        xpath.select1("/resources/string[@name='text_noticehomepage5']", doc)
          .firstChild.data
      );
      setText_noticehomepage6(
        xpath.select1("/resources/string[@name='text_noticehomepage6']", doc)
          .firstChild.data
      );
      setText_noticehomepage7(
        xpath.select1("/resources/string[@name='text_noticehomepage7']", doc)
          .firstChild.data
      );

      setText_noticehomepage9(
        xpath.select1("/resources/string[@name='text_noticehomepage9']", doc)
          .firstChild.data
      );
      setText_title1(
        xpath.select1("/resources/string[@name='text_title1']", doc).firstChild
          .data
      );
      setText_title2(
        xpath.select1("/resources/string[@name='text_title2']", doc).firstChild
          .data
      );
      setText_title3(
        xpath.select1("/resources/string[@name='text_title3']", doc).firstChild
          .data
      );
      setText_title4(
        xpath.select1("/resources/string[@name='text_title4']", doc).firstChild
          .data
      );
      setText_title5(
        xpath.select1("/resources/string[@name='text_title5']", doc).firstChild
          .data
      );
      setText_title6(
        xpath.select1("/resources/string[@name='text_title6']", doc).firstChild
          .data
      );
      setText_title7(
        xpath.select1("/resources/string[@name='text_title7']", doc).firstChild
          .data
      );
      setText_title8(
        xpath.select1("/resources/string[@name='text_title8']", doc).firstChild
          .data
      );
      setText_noticehomepage8(
        xpath.select1("/resources/string[@name='text_noticehomepage8']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="wrapper-home" id="otherPages">
        <div className="hero-banner-home">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-9 col-md-10">
                <div className="hero-banner-content">
                  <h2 className="hometextnight">{text_noticehomepage}</h2>
                  <p>
                    Your Gateway to Unforgettable Experiences!
                    <br />
                    {text_noticehomepage1}
                  </p>
                  <div className="d-flex justify-content-center gap-3">
                    <button
                      className="main-btn btn-hover res-main-btn"
                      onClick={() => navigate("/all-event")}
                    >
                      {" "}
                      {text_browseeve}
                    </button>
                    <button
                      className="main-btn btn-hover res-main-btn"
                      onClick={() => navigate("/contact-us")}
                    >
                      {" "}
                      {text_partnerwithus}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explore-events p-80">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="event-filter-items">
                  <h3>Categories</h3>
                  <div className="featured-controls">
                    <div className="filter-tag">
                      {AllWeekLink.map((e) => {
                        return (
                          <>
                            <Link to={e.link} className={e.class}>
                              {e.value}
                            </Link>
                          </>
                        );
                      })}
                    </div>
                    {/* <div className="controls">
                      {AllEventCategory.map((e) => {
                        return (
                          <>
                            <button
                              type="button"
                              className="control"
                              data-filter={e.datafilter}
                            >
                              {e.value}
                            </button>
                          </>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="main-title">
                  <h3>{text_exploreevent}</h3>
                </div>
                <PopularEvent/>
                <UpcomingEvent/>
                <AllEventCard />
              </div>
            </div>
          </div>
        </div>
        <div className="host-engaging-event-block p-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="main-title">
                  <h3>{test_foreventatt}:</h3>
                  <p>{text_noticehomepage2}</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="engaging-block">
                  <OwlCarousel className="owl-theme" loop margin={10} nav>
                    {sliderDetails.map((e) => (
                      <div className="item">
                        <div className="main-card attendees-card">
                          <div className="host-item">
                            <div className="host-img">
                              <img src={e.img} alt="" />
                            </div>
                            <h4>{e.title}</h4>
                            <p>{e.para}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-block p-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <div className="main-title">
                  <h3>{text_foreventorg}:</h3>
                  <p></p>
                </div>
                {text_noticehomepage3}
              </div>
              <div className="col-lg-12">
                <div className="feature-group-list">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6">
                      <div className="feature-item mt-46">
                        <div className="feature-icon">
                          <img src={featurIcon1} alt="" />
                        </div>
                        <h4>{text_noticehomepage4}:</h4>
                        <p>{text_noticehomepage5}</p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                      <div className="feature-item mt-46">
                        <div className="feature-icon">
                          <img src={featurIcon2} alt="" />
                        </div>
                        <h4>{text_noticehomepage6}:</h4>
                        <p>{text_noticehomepage7}</p>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6">
                      <div className="feature-item mt-46">
                        <div className="feature-icon">
                          <img src={featurIcon3} alt="" />
                        </div>
                        <h4>{text_noticehomepage8}:</h4>
                        <p>{text_noticehomepage9}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default HomePage;
